<!--
 * ======================================
 * 说明： 教师端 - 学业水平
 * 作者： Silence
 * 文件： study.vue
 * 日期： 2023/8/3 0:32
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="p-6" v-loading="loading">
    <canvas v-show="false" id="mycanvas"></canvas>
    <div class="my-4">
      <el-form :inline="true" :model="formSearch" class="web-form-inline">
<!--        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleLoadDataInfo" @refresh="handleLoadDataInfo"></SemesterClassSelect>-->
        <el-form-item label="学期" prop="semesterId">
          <el-select v-model="formSearch.semesterId" clearable>
            <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-cascader clearable v-model="classId" @change="handleClasses" :options="classesTree" :props="{value:'id',label:'name'}" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-right" @click="handleLoadDataInfo">查询</el-button>
          <el-button type="success" @click="onDrawReport">导出报告</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick" v-if="options.length">
      <el-tab-pane :label="menu.label" :name="index.toString()" v-for="(menu,index) in options" :key="index">
        
        <otherGrades ref="otherGrades" v-model="formSearch" :isIE="false" :file-name="menu.label" :headers="menu.children"
                     v-if="activeName==index"/>
      
      </el-tab-pane>
    </el-tabs>
    <el-empty v-else description="暂无任务数据"></el-empty>
  </div>
</template>
<script type="text/javascript">

import otherGrades from "../web/components/otherGrades";
import blockScoreList from "../web/components/blockScoreList.vue";
import drawReport from '@/mixin/drawReport.js'
export default {
  name: "medal",
  components: {
    otherGrades,
    blockScoreList
  },
  mixins: [drawReport],
  data() {
    return {
      loading: false, //加载中
      classId:[],
      //搜索表单结构
      formSearch: {
        semesterId: "",//学期ID
        blockId: "",//模块ID
        grade: '',//年级ID
        classId: '',//班级ID
        subjectId: 3,//任课ID
        realName: ''
      },
      activeName: '0',
      options: [],
      blockOptions: [],
      semesterOptions:[],
      classesTree:[],
    };
  },
  /**
   * 接收父级传递的数据
   */
  async mounted() {
     await this.handleLoadSemesterOptions();
     await this.getClassesTree();
  },
  methods: {
    async handleLoadSemesterOptions(){
      await this.$cloud.get("semester/list",{
        size:99999,
        page:1,
      }).then(res => {
        this.semesterOptions = res.children;
        this.formSearch['semesterId'] = res.children?.[0]?.id
      })
    },
    // 获取班级待选树状数据
    async getClassesTree(){
      await this.$cloud.post('/classes/list/tree').then(res=>{
        this.classesTree = res
        let a = res[0]?.id || '';
        let b = res[0]?.children[0]?.id || '';
        this.classId = [a,b]
        this.formSearch['classId'] = this.classId[1]
        this.formSearch['grade'] = this.classId[0]
        
        this.handleLoadDataInfo()
      })
    },
    handleClasses(){
      this.formSearch['classId'] = ''
      this.formSearch['grade'] = ''
      if (this.classId.length) {
        this.formSearch['grade'] = this.classId[0]
        this.formSearch['classId'] = this.classId[1]
      }
    },
    
    handleClick() {
      let menuInfo = this.options[this.activeName];
      this.$nextTick(() => {
        if( this.formSearch && menuInfo){
          this.formSearch.blockId = menuInfo["key"];
          console.log('otherGrades',this.$refs.otherGrades,this.activeName)
          if(this.$refs.otherGrades &&  this.$refs.otherGrades[0]){
            console.log('加载tab数据',this.$refs.otherGrades[0])
            let dom = this.$refs.otherGrades[0]
            dom.handleLoadDataList()
          }
        }
      })
      
    },
    async handleLoadDataInfo() {
      await this.handleStudyOption();
      await this.handleClick();
    },
    /**
     * 获取数据选项
     */
    handleStudyOption() {
      if(!this.formSearch.grade){
        return
      }
      //获取分页数据结构
      return this.$cloud.get("task/block", this.formSearch).then(res => {
        this.options = [];
        if (res.length && res) {
          this.options = res
        }
      }).catch(err => {
        this.options = [];
      })
    },
  },
};
</script>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
